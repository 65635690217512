.dashboard-wrapper {
	width: 100%;
	display: flex;
	color: #62ff88;

	.join-us {
		font-size: 64px;
		font-style: normal;
		font-weight: 600;
		letter-spacing: -1px;
		line-height: 1.1;
	}

	.pd-b-10{
		padding-bottom: 20px !important;

	}

	.color-green {
		color: #62ff88;
	}

	.btn-primary {
		border-radius: 4px;
		padding-top: 16px;
		padding-right: 40px;
		padding-bottom: 16px;
		padding-left: 40px;
		background-color: #62ff88;
		color: #063559;
		font-style: normal;
		font-weight: 600;
		line-height: 1;
	}

	.rigid-build {}

	.mr-t-24 {
		padding-top: 24px;
	}

	.font-16 {
		font-size: 16px;
	}

	.pd-64 {
		padding: 64px;
	}

	.pd-l-r-64 {
		padding-inline: 64px;
	}

	.ecosystem {
		background-color: #f5eac1;
		padding-top: 6vw;
		padding-right: 6vw;
		padding-bottom: 6vw;
		padding-left: 6vw;
		color: #000000;
	}

	.positive-growth {
		color: #000000;
		font-size: 6vw;
		font-style: normal;
		font-weight: 700;
		letter-spacing: 0px;
		line-height: 0.9;
		text-decoration: none;
		text-transform: none;
	}
}

.color-green {
	color: #62ff88;
}