@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }

  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

.navbar-nav,
.active {
  text-decoration: underline;

}

.video-item {
  margin-right: 20px;
}

.list-style-ul {
  list-style-type: disclosure-closed !important;
}

.pri-color {
  color: #183054;
}


/* Home.css */
.home {
  text-align: center;
}

header {
  background-color: #333;
  color: white;
  padding: 20px;
}

nav a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

main {
  margin: 20px;
}

#featured {
  margin-bottom: 40px;
}

.vehicle-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.vehicle-card {
  width: 250px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.vehicle-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.vehicle-card h3 {
  margin: 10px 0;
}

/* Add more styles as needed */

.btn-black {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  background-color: #000000;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #000000;
  border-radius: 25px 25px 25px 25px;
  padding: 5px 20px 5px 20px;
  color: white;
  flex-grow: 1;
  order: 10;
  display: inline-block;
}

@media only screen and (max-width: 600px) {

  .hide-image {
    display: none;
  }
}