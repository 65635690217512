body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (min-width: 1601px) {
  .align-wide {
    max-width: 1440px !important;
  }

  .dashboard-wrapper {
    display: flex;
    justify-content: center;
  }

  .dashboard-content, .contact-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .header-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .navbar-fixed-top {
    display: flex;
  }

  .full-width {
    max-width: 2600px;
    min-width: 2600px;
  }

  .ecosystem {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .pd-64 {
    padding: 30px !important;
  }

  .pd-l-r-64 {
    padding-inline: 30px !important;
  }
}

@media screen and (min-width: 767px) {
  .pd-64 {
    padding: 64px !important;
  }

  .pd-l-r-64 {
    padding-inline: 64px !important;
  }
}

@media screen and (max-width: 1400px) {

  #root {
    width: 100%;
  }
}

@import "./css/main.scss";
@import "~react-image-gallery/styles/css/image-gallery.css";